<div *ngIf="isError" class="loader__container">
  <h3>We're sorry. Something went wrong. </h3>
</div>

<div *ngIf="loading && !isError" class="loader__container">
    <div class="loader"></div>
</div>

<div class="full-container" *ngIf="!loading && !isError">
    <app-digitization-top-nav
        [document]="state.reviewQueueItem"
        [documentFile]="state.documentFile"
        (saveMethod)="saveAndStay()"
        (nextMethod)="saveAndComplete()"
    >
        <div class="btn-group undo-redo-button undo-button">
          <button class="btn btn-link undo-redo-link" (click)="undo()" [disabled]="noMoreUndos" title="Undo"><i *ngIf="!undoButtonLoading" class="fa fa-rotate-left fa-lg"></i><div *ngIf="undoButtonLoading" class="loader button-loader"></div></button>
        </div>
        <div class="btn-group undo-redo-button">
          <button class="btn btn-link undo-redo-link" (click)="redo()" [disabled]="noMoreRedos" title="Redo"><i *ngIf="!redoButtonLoading" class="fa fa-rotate-right fa-lg"></i><div *ngIf="redoButtonLoading" class="loader button-loader"></div></button>
        </div>
        <div *ngIf="enableAccessToDoc" class="template-toggle">
            <p>Show Document</p>
            <i class="fas fa-toggle-off" (click)="toggleDocumentExplorer($event)"
               [ngClass]="{'fa-toggle-off': !documentSidebarIsOpen, 'fa-toggle-on': documentSidebarIsOpen}"></i>
        </div>


        <div *ngIf="!isDASStatement" class="template-toggle">
            <p>Show Template</p>
            <i class="fas" (click)="toggleTemplateView()"
               [ngClass]="{'fa-toggle-off': shouldHideTemplate, 'fa-toggle-on': !shouldHideTemplate}"
            ></i>
        </div>

        <div *ngIf="!enableAccessToDoc || true">
            <button class="btn btn-empty-primary" (click)="downloadFile()">
                <i class="fas fa-download"></i>Download
            </button>
        </div>
        <div>
            <a (click)="reAutonormDocument()" class="link-primary"><i class="fa fa-rotate"></i>Re-Spread</a>
        </div>
    </app-digitization-top-nav>
  <ul class="dropdown-menu" [ngStyle]="contextMenuStyle">
    <ng-container *ngIf="contextMenuMode === 'row'">
      <ng-container>
        <li (click)="cutSelected()"><span>Cut <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>x</kbd></span></span></li>
        <li (click)="copySelected()"><span>Copy <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>c</kbd></span></span></li>
        <li (click)="pasteSelected()"><span>Paste <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>v</kbd></span></span></li>
        <li *ngIf="!isDASStatement" class="dropdown-divider"></li>
      </ng-container>
      <li *ngIf="enableAccessToDoc && !isDASStatement" (click)="openMergeTablesInterface()">
        <span>Merge New Table</span>
      </li>
      <ng-container *ngIf="!contextMenuRow.header && !isDASStatement">
        <li *ngIf="enableAccessToDoc" (click)="openManageFootnotesInterface()">
          <span>Manage Footnotes ({{contextMenuRow?.footnotes?.length || 0}})</span>
        </li>
        <li *ngIf="canCategorize && !contextMenuRow.categorizedTo" (click)="categorizeSelectedRows(false)"><span>Categorize <span class="keyboard-shortcuts"><kbd>c</kbd></span></span></li>
        <li *ngIf="canCategorize && !contextMenuRow.categorizedTo" (click)="categorizeSelectedRows(true)"><span>Validate <span class="keyboard-shortcuts"><kbd>v</kbd></span></span></li>
        <li *ngIf="contextMenuRow.categorizedTo && contextMenuRow.itemClass!=='supplementalItem'" (click)="uncategorizeSelectedRows()"><span>Uncategorize <span class="keyboard-shortcuts"><kbd>u</kbd></span></span></li>
        <li *ngIf="canCategorize" (click)="invertSelectedRows()"><span>Invert Values<span class="keyboard-shortcuts"><kbd>alt</kbd>+<kbd>i</kbd></span></span></li>
        <li (click)="addAdjustmentRow(contextMenuObjectIndex, contextMenuRow)">
          <span>
            Add Adj. Row
            <span class="keyboard-shortcuts"><kbd>alt</kbd>+<kbd>a</kbd></span>
          </span>
        </li>
        <li class="dropdown-divider"></li>
      </ng-container>
      <li *ngIf="!isDASStatement" (click)="deleteRowAtIdx(contextMenuObjectIndex)"><span>Delete Row</span></li>
      <li *ngIf="!isDASStatement" (click)="addRowAtIdx(contextMenuObjectIndex)"><span>Add Row Above</span></li>
      <li *ngIf="!isDASStatement" (click)="addRowAtIdx(contextMenuObjectIndex + 1)"><span>Add Row Below <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>+</kbd></span></span></li>
    </ng-container>
    <ng-container *ngIf="contextMenuMode === 'column'">
      <ng-container>
        <li *ngIf="!isDASStatement" (click)="cutSelected()"><span>Cut <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>x</kbd></span></span></li>
        <li (click)="copySelected()"><span>Copy <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>c</kbd></span></span></li>
        <li *ngIf="!isDASStatement" (click)="pasteSelected()"><span>Paste <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>v</kbd></span></span></li>
        <li class="dropdown-divider"></li>
      </ng-container>
      <li (click)="deleteSelectedColumns()"><span>Delete Column(s)</span></li>
      <li *ngIf="!isDASStatement" (click)="addColumnAtIdx(contextMenuObjectIndex)"><span>Add Column to Left</span></li>
      <li *ngIf="!isDASStatement" (click)="addColumnAtIdx(contextMenuObjectIndex + 1)"><span>Add Column to Right <span class="keyboard-shortcuts"><kbd>ctrl</kbd>+<kbd>+</kbd></span></span></li>
    </ng-container>
    <ng-container *ngIf="contextMenuMode === 'sourceCell'">
      <li (click)="editCellAndStopEvent($event, contextMenuRow, contextMenuObjectIndex)">
        <span>Edit Cell
          <span class="keyboard-shortcuts"><kbd>F2</kbd></span>
        </span>
      </li>
      <li *ngIf="showSourceEnabledInContextMenu && contextMenuRow.itemClass!=='supplementalItem'" (click)="showSourceForCell($event, contextMenuRow, contextMenuObjectIndex)">
        <span>Show Source</span>
      </li>
      <li *ngIf="!!contextMenuRow.cells[contextMenuObjectIndex].sourceComments && !!contextMenuRow.cells[contextMenuObjectIndex].sourceComments.length">
          <span (click)="showComment(contextMenuRow, contextMenuRow.cells[contextMenuObjectIndex])">
              View Comment
          </span>
      </li>
        <li *ngIf="!contextMenuRow.cells[contextMenuObjectIndex].sourceComments || !contextMenuRow.cells[contextMenuObjectIndex].sourceComments.length">
          <span (click)="addComment(contextMenuRow, contextMenuRow.cells[contextMenuObjectIndex])">
            Add Comment
          </span>
        </li>
    </ng-container>
    <ng-container *ngIf="contextMenuMode === 'dasCell'">
        <li *ngIf="!contextMenuRow.cells[contextMenuObjectIndex].sourceComments || !contextMenuRow.cells[contextMenuObjectIndex].sourceComments.length">
            <span (click)="openCommentModal(contextMenuRow, contextMenuRow.cells[contextMenuObjectIndex])">
                Add Comment
            </span>
        </li>
        <li *ngIf="!!contextMenuRow.cells[contextMenuObjectIndex].sourceComments && !!contextMenuRow.cells[contextMenuObjectIndex].sourceComments.length">
            <app-comment-container
                [commentText]="contextMenuRow.cells[contextMenuObjectIndex].sourceComments[0].comment"
                (editContent)="openCommentModal(contextMenuRow, contextMenuRow.cells[contextMenuObjectIndex])"
                (deleteContent)="openCommentDeleteModal(contextMenuRow.cells[contextMenuObjectIndex])"
            ></app-comment-container>
        </li>
    </ng-container>
  </ul>
  <div class="data-container">
    <div class="left-sidebar in big"
         *ngIf="documentSidebarIsOpen"
            id="doc-explorer-left-sidebar"
    >
        <app-document-view-split-screen
            [pageImgUrls]="state.documentFile.pageImageUrls"
            [originalFileUrl]="originalDocumentFileUrl"
            [documentFileType]="state.documentFile.fileType"
            [viewSourceBox]="viewSourceBox"
            [viewSourcePage]="viewSourcePage"
            (closeSplitscreen)="closeDocumentExplorer()"
            (rerenderEvent)="_crd.detectChanges()"
        ></app-document-view-split-screen>
    </div>
    <div class="left-sidebar-spacer in big" *ngIf="documentSidebarIsOpen"></div>
    <div id="src-container" *ngIf="state.sourceRows.length" class="source-container">
      <table [ngClass]="{
        'cannot-categorize': !canCategorize && !isDASStatement
      }">
        <thead class="sticky">
          <tr class="column-header-row">
            <th class=""></th>
            <th class=" categorization-td" [class.include-exclude-cell]="isDASStatement"></th>
            <th class=" invert-td"></th>
            <th></th>
            <th class=" line-item-td"></th>
            <th
              class="column-header"
              *ngFor="let column of state.columns; let colIdx=index"
              [ngClass]="{
                'column-selected': column.selected,
                'index-0': colIdx === 0,
                'index-last': colIdx === (state.columns.length - 1),
                'disabled-column': isDASStatement,
              }"
              [class.das-selected]="isDASStatement"
              [ngStyle]="{
                'pointer-events': isDASStatement ? 'none' : 'auto',
                'color': isDASStatement ? 'gray' : 'inherit'
              }"
              (click)="!isDASStatement && selectColumn($event, colIdx, column)"
              (contextmenu)="!isDASStatement && rightClickedColumn($event, colIdx, column)"
            >
              {{headerForColumnIndex(colIdx)}}
              <ng-container *ngIf="willColumnOverwrite(column) && !isDASStatement">
                <br /><p class="column-header-help-text">Overwrites previous periods</p>
              </ng-container>
            </th>
          </tr>
          <tr class="date-header-row">
            <th class="cat-header "></th>
            <th class="cat-header  categorization-td" [class.include-exclude-cell]="isDASStatement"></th>
            <th class="cat-header  invert-td"></th>
            <th></th>
            <th class="cat-header  line-item-td header-row-title">Statement Date</th>
            <th class="date-header"
              *ngFor="let column of state.columns; let colIdx=index"
              [ngClass]="{
                'first-header-column': colIdx === 0,
                'column-selected': column.selected,
                'projection': column.scenario === scenarioTypeProjection
              }"
              [class.das-selected]="isDASStatement"
            >
              <app-double-click-to-edit
                *ngIf="!isDASStatement; else readOnlyStatementDate"
                [value]="column.statementDate"
                mask="__/__/____"
                [alignRight]="true"
                [editing]="editingColumnHeaderIndex == colIdx && editingColumnHeader == 'statementDate'"
                (changed)="columnHeaderChanged($event, column, 'statementDate')"
                (enteredEditMode)="editColumnHeader(colIdx, 'statementDate')"
              ></app-double-click-to-edit>
                <ng-template #readOnlyStatementDate>
                    <span>{{column.statementDate}}</span>
                </ng-template>
            </th>
          </tr>
          <tr class="sub-header-row">
            <th class="cat-header "></th>
            <th class="cat-header  categorization-td" [class.include-exclude-cell]="isDASStatement"></th>
            <th class="cat-header  invert-td"></th>
            <th></th>
            <th class="header-row-title  line-item-td">Reporting</th>
            <th class="th reporting-interval-header"
              *ngFor="let column of state.columns; let colIdx=index"
              [ngClass]="{
                'first-header-column': colIdx === 0,
                'column-selected': column.selected,
                'projection': column.scenario === scenarioTypeProjection
              }"
              [class.das-selected]="isDASStatement"
            >
              <select class="inline-edit right"
                      *ngIf="!isDASStatement; else readOnlyInterval"
                      [(ngModel)]="column.reportingInterval"
                      (change)="columnHeaderDropDownChanged()"
              >
                <option value="MONTHLY">Monthly</option>
                <option value="QUARTERLY">Quarterly</option>
                <option value="SEMI_ANNUALLY">Semi-annual</option>
                <option value="ANNUALLY">Annual</option>
                <option value="FISCAL_YTD">Fiscal YTD</option>
              </select>
                <ng-template #readOnlyInterval>
                    <span>{{column.reportingInterval}}</span>
                </ng-template>
            </th>
          </tr>
          <tr class="sub-header-row">
              <th class="cat-header "></th>
              <th class="cat-header  categorization-td" [class.include-exclude-cell]="isDASStatement"></th>
              <th class="cat-header  invert-td"></th>
              <th></th>
              <th class="header-row-title  line-item-td">Scenario</th>
              <th class="th reporting-interval-header"
                  *ngFor="let column of state.columns; let colIdx=index"
                  [ngClass]="{
                'first-header-column': colIdx === 0,
                'column-selected': column.selected,
                'projection': column.scenario === scenarioTypeProjection,
                'projection-selected': column.scenario === scenarioTypeProjection
              }"
              [class.das-selected]="isDASStatement"
              >
                  <select class="inline-edit right"
                        *ngIf="!isDASStatement; else readOnlyScenario"
                          [(ngModel)]="column.scenario"
                          (change)="columnHeaderDropDownChanged()"
                  >
                      <option value="HISTORICAL">Historical</option>
                      <option value="PROJECTION">Projection</option>
                  </select>
                   <ng-template #readOnlyScenario>
                    <span>{{column.scenario}}</span>
                </ng-template>
              </th>
          </tr>
          <tr class="sub-header-row">
            <th class="cat-header "></th>
            <th class="cat-header  categorization-td" [class.include-exclude-cell]="isDASStatement"></th>
            <th class="cat-header  invert-td"></th>
            <th></th>
            <th class="header-row-title  line-item-td">Preparation</th>
            <th class="prep-type-header"
              *ngFor="let column of state.columns; let colIdx=index"
              [ngClass]="{
                'first-header-column': colIdx === 0,
                'column-selected': column.selected,
                'projection': column.scenario === scenarioTypeProjection
              }"
              [class.das-selected]="isDASStatement"
            >
            <ng-container *ngIf="!isDASStatement; else readOnlyPreparationType">
              <select *ngIf="column.scenario !== scenarioTypeProjection"
                      class="inline-edit right"
                      [(ngModel)]="column.preparationType"
                      (change)="columnHeaderDropDownChanged()"
              >
                <option value="UNKNOWN">Unknown</option>
                <option value="UNQUALIFIED">Unqualified</option>
                <option value="QUALIFIED">Qualified</option>
                <option value="TAX_RETURN">Tax Return</option>
                <option value="REVIEWED">Reviewed</option>
                <option value="CPA_PREP">CPA Prepared</option>
                <option value="NTR">NTR</option>
                <option value="MANAGEMENT">Management</option>
              </select>
              <select *ngIf="column.scenario === scenarioTypeProjection"
                      class="inline-edit right"
                      [(ngModel)]="column.preparationType"
                      (change)="columnHeaderDropDownChanged()"
              >
                <option value="MANAGEMENT">Management</option>
                <option value="CPA_PREP">CPA Prepared</option>
                <option value="ANALYST">Analyst</option>
              </select>
            </ng-container>
                 <ng-template #readOnlyPreparationType>
                    <span>{{column.preparationType}}</span>
                </ng-template>
            </th>
          </tr>
          <tr class="table-headers">
              <th class="cat-header type-td header-content"  >{{isDASStatement ? 'Weight' : 'Type'}}</th>
              <th class="cat-header categorization-td down header-content"  [class.include-exclude-cell]="isDASStatement">
                {{isDASStatement ? 'Include' : 'Categorization'}}
              </th>
              <th class=" invert-td header-content" ></th>
              <th class="sticky-spacer header-content" ></th>
              <th class="cat-header  line-item-td header-content" >Line Item<button *ngIf="state.statement.detectedLanguage && state.statement.detectedLanguage.language != 'en'" class="swap-translation-button" (click)="swapLanguage()">{{swapTranslationLabel}}</button>
              </th>
              <th
                *ngFor="let column of state.columns; let colIdx=index"
                [ngClass]="{
                  'column-selected': column.selected
                }"
                [class.das-selected]="isDASStatement"
                class="header-content"
              >

              </th>
          </tr>
          <ng-container *ngIf="!isDASStatement">
              <tr class="reference-row" *ngFor="let row of state.referenceRows; let rowIdx = index">
                <td
                  class=""
                  [ngClass]="{
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                ></td>
                <td
                  class=" categorization-td"
                  [ngClass]="{
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                ></td>
                <td
                  class=" invert-td"
                  [ngClass]="{
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                ></td>
                <td
                  class=""
                  [ngClass]="{
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                ></td>
                <td
                    class=" line-item-td"
                    [ngClass]="{
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                >{{row.label}}</td>
                <td *ngFor="let cell of row.cells; let cellIdx = index"
                  class="reference-row-value"
                  [ngClass]="{
                    'column-selected': state.columns[cellIdx].selected,
                    'last-reference-row': rowIdx === state.referenceRows.length - 1
                  }"
                  [class.das-selected]="isDASStatement"
                >
                  {{cell.value | number:'1.2-2'}}
                </td>
              </tr>
          </ng-container>
        </thead>
        <tbody>
          <tr
          (click)="selectRow($event, idx, row)"
          [ngClass]="{
            'selected': row.selected,
            'mapped': row.categorizedTo,
            'unlabeled': !row.categorizedTo && row.label == UNLABELED_ROW_PREFIX,
            'header': row.header,
            'active-categorization': row.showMappingBox,
            'display-bold': row.displayBold,
            'editing-disabled': !row.displayBold && isCellEditingDisabled(row)
          }"
          *ngFor="let row of state.sourceRows; let idx = index"
        >
          <td *ngIf="!isDASStatement; else weightAdjustmentCell" class="cat-type-container ">
            <ng-container *ngIf="!row.header && canCategorize">
              <span class="cat-type component"
                [ngClass]="{
                  'selected': row.categorizedTo && row.categorizationType == 'component',
                  'hidden': row.categorizedTo && row.categorizationType == 'validation',
                  'selection-in-progress': row.showMappingBox && !row.mappingBoxValidation,
                  'non-editable': row.itemClass==='supplementalItem'
                }"
                (click)="selectAndCategorize($event, row, idx, false)"
              >
                C
              </span>
              <span class="cat-type validation"
                [ngClass]="{
                  'selected': row.categorizedTo && row.categorizationType == 'validation',
                  'hidden': row.categorizedTo && row.categorizationType == 'component',
                  'selection-in-progress': row.showMappingBox && row.mappingBoxValidation,
                  'non-editable': row.itemClass==='supplementalItem'
                }"
                (click)="selectAndCategorize($event, row, idx, true)"
              >
                V
              </span>
            </ng-container>
          </td>
          <ng-template #weightAdjustmentCell>
            <td class="weight-adjustment-cell">
              <div class="input-group"
                   *ngIf="row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem'"
                   >

                  <input class="form-control weight-input-control"
                         type="text"
                         [class.is-invalid]="percent?.errors?.pattern"
                         #percent="ngModel"
                         name="percent{{idx}}"
                         pattern="[0-9]+(\.[0-9]+)?"
                         [(ngModel)]="row.weightPercentage"
                         [disabled]="row.itemClass === 'NonAdjustableStandardItem'"
                         (change)="weightInputChange(row)"
                         >

                  <div class="input-group-append weight-input-control-append">
                      <span class="input-group-text" >%</span>
                  </div>

                  <span class="invalid-feedback"
                        *ngIf="percent?.errors?.pattern">
                      Please enter a valid numerical value.
                  </span>
              </div>
          </td>

          </ng-template>
          <td *ngIf="!isDASStatement; else includeExcludeCell"
          class="categorization-td"
          (contextmenu)="rightClickedRow($event, idx, row)" >

          <div class="categorization">

              <div *ngIf="row.showMappingBox"
                   (click)="stopPropagation($event)"
                   [ngClass]="{'validation': row.mappingBoxValidation}">

                  <div class="autocomplete-wrapper" id="mappingAutocompleteContainer">
                      <ng-select class="custom"
                                 [items]="getAutocompleteOptionsForRow(row)"
                                 bindLabel="value"
                                 bindValue="id"
                                 autofocus
                                 class="autocomplete"
                                 (change)="rowMapped($event, row)"
                                 [markFirst]="true"
                                 minTermLength="1"
                                 dropdownPosition="{{getAutocompleteOptionsForRow(row).length <= 1 ? 'top' : 'auto'}}">
                      </ng-select>
                  </div>

              </div>
              <div class="mapping" [ngClass]="{'non-editable': row.itemClass==='supplementalItem'}" *ngIf="row.categorizedTo && !row.showMappingBox">
                {{row.categorizationLabel}}
              </div>

              <div class="categorization-placeholder"
                   *ngIf="!row.categorizedTo && !row.showMappingBox">
                  Select type then categorize
              </div>
          </div>
      </td>

          <ng-template #includeExcludeCell>
            <td class="include-exclude-cell" >
              <ng-container *ngIf="row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem' || row.isExcludableInOtherCalculations">
                  <input type="checkbox"
                         [id]="row.lineItemId"
                         class="form-check-input"
                         [(ngModel)]="row.include"
                         [disabled]="row.itemClass === 'NonAdjustableStandardItem'"
                         (change)="toggleIncludeItem(row)"
                         [class.weight]="row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem'"
                        >
                  <label class="form-check-label ps-2"
                         [for]="row.lineItemId"
                         [class.weight]="row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem'"
                         >
                      Include
                  </label>
              </ng-container>
          </td>


          </ng-template>
          <td class=" invert-td" (contextmenu)="rightClickedRow($event, idx, row)">
            <span class="invert"></span>
          </td>
          <td (contextmenu)="rightClickedRow($event, idx, row)"></td>
          <td class=" line-item-td" (contextmenu)="rightClickedRow($event, idx, row)">
            <div [className]="'indent indent-' + row.indentation"></div>
            <div class="line-item"
            [class.percent-padding]="row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem'"
            [ngClass]="{
              'calculated': row.calculated,
              'suggested': row.suggestedChange,
            }" title="{{ row.userFacingLabel ? row.userFacingLabel : row.label}}">
              <app-double-click-to-edit
                [value]="row.userFacingLabel ? row.userFacingLabel : ((viewInNativeLanguage) ? row.label : row.translatedLabel)"
                (changed)="rowLabelChanged($event, row)"
                (enteredEditMode)="editRowLabel(idx)"
                [editing]="editingRowLabelIndex == idx"
                [invertValue]="row.invertValue"
                [isEditingDisabled]="row?.labelIsLocked || !!row?.userFacingLabel"
                [footnotePresent]="!!row?.footnotes?.length"
              ></app-double-click-to-edit>
            </div>

            <div class="goalSeekTooltip" *ngIf="row.suggestedChange && visibleSuggestionsOriginId && row.suggestionOrigin === visibleSuggestionsOriginId" (click)="removeSuggestionFromRow($event, row)">
              {{ row.suggestedChange }}
            </div>
          </td>
          <td
            *ngFor="let cell of row.cells; let cellIdx = index"
            class="no-padding"
            [ngClass]="{
              'column-selected': state.columns[cellIdx].selected,
              'das-selected': isDASStatement,
              'selected-celll': row.cellSelectedIndex === cellIdx
            }"
          >
            <div class="outer-height"
            [ngClass]="{
              'unselected-cell':row.cellSelectedIndex !== cellIdx,
              'percent-padding':row.itemClass === 'AdjustedStandardItem' || row.itemClass === 'NonAdjustableStandardItem'
            }"
            >
              <app-statement-table-cell *ngIf="!row.header"
                [cellForSourceComments]="cell"
                [value]="cell.rawText"
                [confidence]="cell.confidence"
                (rerenderEvent)="_crd.detectChanges()"
                (cellValueChanged)="doneEditingCell($event, row, cellIdx)"
                (enteredEditMode)="editCell(row, cellIdx)"
                [isEditing]="row.cellEditingIndex === cellIdx"
                [isEditingDisabled]="isCellEditingDisabled(row)"
                [isSelected]="row.cellSelectedIndex === cellIdx"
                [isNumeric]="cellShouldAlignRight(cell)"
                (cellSelected)="highlightCell(row, cellIdx, idx)"
                (click)="highlightCell(row, cellIdx, idx)"
                (rightClickedOnCell)="rightClickedCell($event, row, cell, cellIdx, idx)"
              ></app-statement-table-cell>
            </div>
          </td>
        </tbody>
      </table>
    </div>
    <div class="empty" *ngIf="!state.sourceRows.length">
      <i class="far fa-file"></i>
      <h1>No source data</h1>
    </div>

    <div *ngIf="!isDASStatement" id="template-spacer" class="template-spacer"></div>
    <div *ngIf="!isDASStatement" id="template" class="template-container">
        <app-comment
            [cell]="cellForComment"
            [row]="rowForComment"
            (saveComment)="saveAndStay()"
            [spreadingState]="state"
        ></app-comment>
        <div class="template-header-container">
          <div class="template-header-inner-container">
            <p class = "template-name">{{ templateName }}</p>
            <ng-container *ngIf="!isTemplateChooserDisabled && availableTemplates.length > 1 && state.documentFile.initialTemplateSelectionHasBeenMade">
              <button class="btn btn-empty-primary change" (click)="showSpreadingTemplateSelectionPopup()">Change Template</button>
            </ng-container>
            <ng-container *ngIf="isTemplateChooserDisabled || availableTemplates.length <= 1 || !state.documentFile.initialTemplateSelectionHasBeenMade">
              <label class = "template-name-label">Template</label>
            </ng-container>
          </div>
          <div class = "header-container">
          <ng-container
              *ngFor="let column of state.columns; let colIdx=index"
          >
              <ng-container *ngIf="column.selected">
                <div class = "statement-date" [ngClass]="column.statementDate ? '' : 'no-date'">{{ column.statementDate || 'Date Missing'}}</div>
              </ng-container>
          </ng-container>

            <label class = "statement-label">Statement</label>
          </div>
        </div>

        <div class="template-view">
        <div class="spreading-template-wrapper">
          <div *ngIf="!state.rows.length">
            You have no spreading template for this type of statement. Don't worry! You can still edit the data and add footnotes.
          </div>
          <ul class="spreading-template" *ngIf="state.rows.length">
            <app-spreading-template-row
              *ngFor="let row of state.rows"
              [row]="row"
              [columnCheckOk]="state.columns[state.getLastSelectedItemIndex('column')].columnCheckOk"
              [selectedColumn]="state.getLastSelectedItemIndex('column')"
              [enableGoalSeek]="enableGoalSeek"
              [goalSeekLineItem]="goalSeekLineItem"
              [viewInNativeLanguage]="viewInNativeLanguage"
              [visibleSuggestionsOriginId]="visibleSuggestionsOriginId"
              [goalSeekCount] = "state.goalSeekCount"
              (clearedCategorization)="clearedCategorization($event)"
              (goalSeek)="goalSeek($event)"
              (hideSuggestions)="hideSuggestions($event)"
            ></app-spreading-template-row>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="tabs" [class.document-sidebar-offset]="documentSidebarIsOpen">
    <button *ngFor="let statement of relatedStatements"
      (click)="switchToStatement(statement)"
      [ngClass]="{
        'active': state.statement.id === statement.id
      }"
    >{{ statement.statementType === DYNAMIC_ADJUSTMENT_STATEMENT && statement?.uniqueDasName ? statement.uniqueDasName : statement.statementType | docType | titlecase }}
    </button>
      <span *ngIf="!isDASStatement" class="current-sum">Sum: {{ currentSum | number:'1.2-2' }}</span>
  </div>
</div>
